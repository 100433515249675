<template>
  <div class="content" v-loading="loadingStatus.getTable">
    <div>
      <el-form ref="staffRef" :inline="true" size="medium" :model="dataForm" label-width="90px">
                <el-form-item label="会长名称：">
                  <!-- <el-input v-model="dataForm.presidentName" placeholder="请输入会长名称"></el-input> -->
                  <el-select style="width: 100%;" v-model="dataForm.presidentName" placeholder="请选择" clearable>
                    <el-option v-for="(item ) in presidentList" :key="item"
                              :label="item" :value="item"></el-option>
                  </el-select>
                </el-form-item>
                
                <el-button type="primary" @click="getTable" size="medium">查询</el-button>
                <el-button size="medium" @click="getReset" plain>重置</el-button>
                <el-button type="warning" size="medium" @click="onExport">导出</el-button>
      </el-form>
    </div>
    <div>
      <el-table
          :data="tableData"
          border
          :stripe="true"
          style="width: 100%">
        <el-table-column prop="date" label="编号" align="center" width="80">
          <template slot-scope="scope">
            <span>{{
                scope.$index + 1 + (page.pageNo - 1) * page.pageSize
              }}</span>
          </template>
        </el-table-column>
        <el-table-column width="180" prop="presidentName" label="会长名称" align="center"></el-table-column>
        <el-table-column prop="bankAccountName" label="账号名" align="center"></el-table-column>
        <el-table-column prop="payeeAccount" width="160px" label="银行账号" align="center"></el-table-column>
<!--        <el-table-column prop="bankBranchName" label="支行名称" align="center"></el-table-column>-->
        <el-table-column prop="alipayAccount" width="160px" label="支付宝账号" align="center"></el-table-column>
        <el-table-column prop="alipayName" width="160px" label="支付宝用户姓名" align="center"></el-table-column>
        <el-table-column prop="outcomeAccount" label="提现金额" width="140px" align="center"></el-table-column>
        <el-table-column prop="date" width="120px" label="提现状态" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 0">申请中</el-tag>
            <el-tag v-else-if="scope.row.status == 1" type="success">已处理</el-tag>
            <el-tag v-else-if="scope.row.status == 2"  type="warning">已驳回</el-tag>
            <el-tag v-else type="info">已取消</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="outcomeDatetime" width="160px" label="申请时间" align="center"></el-table-column>
        <!--        <el-table-column prop="date" label="处理时间" align="center"></el-table-column>-->
        <el-table-column label="操作" width="120px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0" style="cursor: pointer;color: #1D90FF;padding-right: 10px" @click="getDealWithReject(scope.row,1)">处理</span>
            <span v-if="scope.row.status == 0" style="cursor: pointer;color: #1D90FF;padding-right: 10px" @click="getDealWithReject(scope.row,2)">驳回</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          class="page"
          background
          @size-change="handleSizeChange"
          @current-change="currentChange"
          :page-sizes="[10, 15, 20]"
          :page-size="1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
          :current-page="page.pageNo"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import loadingStatusMixin from "@/mixins/loadingStatus";
import Excel from "@/util/excelGenerator";
import {
  settlementAdminAdminPage, settlementAdminPageOperation,presidentInfo
} from "../../service/moudules/promotionLinkApi";
export default {
  name: "staffPromotion",
  mixins: [loadingStatusMixin],
  data (){
    return {
      dataForm: {
        presidentName: ''
      },
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      dialogVisible: false,
      presidentList: []
    }
  },
  mounted() {
    this.getTable();
    this.getPresidentInfo();
  },
  methods:{
    async getPresidentInfo() {
      let res = await presidentInfo();
      console.log("会长结果：",res);
      this.presidentList = res.data;
    },
    async getTable() {
      if ( this.hasLoadingState('getTable') ) return;
      try {
        this.addLoadingState('getTable');
        let params = {
          pageNo: this.page.pageNo,
          pageSize: this.page.pageSize
        };
        const searchFormValue = this.dataForm;
        Object.keys(searchFormValue).map((key) => {
          searchFormValue[key] && (params[key] = searchFormValue[key]);
        });
        let res = await settlementAdminAdminPage(params);
        this.removeLoadingState("getTable");
        if ( res.code == 200) {
          this.tableData = res.data.records;
          // this.page.pageNo = res.data.pages;
          this.page.pageNo = res.data.current;
          this.page.total = res.data.total;
        } else {
          this.removeLoadingState("getTable");
          this.$message.error(`${res.msg}！`);
        }
      } catch (e) {
        this.removeLoadingState("getTable");
      }
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.page.pageNo = 1;
      this.getTable();
    },
    currentChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getTable();
    },
    getReset() {
      this.dataForm = {
        presidentName: '',
      };
      this.getTable();
    },
    getDealWithReject(row,code) {
      this.$confirm(`是否${code === 1 ? '处理' : '驳回'}该数据?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let params = {
          presidentId: row.presidentId,
          flag: code,
          outcomeId: row.outcomeId
      }
        let res = await settlementAdminPageOperation(params);
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: `${code === 1 ? '处理' : '驳回'}成功!`
          });
          this.getTable();
        } else {
          this.$message.error(res.msg)
        }

      })
    },
    async onExport() {
      if (this.hasLoadingState("onExport")) return;
      try {
        const params = {
          pageNo: 1,
          pageSize: this.page.total
        };
        const searchFormValue = this.dataForm;
        Object.keys(searchFormValue).map((key) => {
          searchFormValue[key] && (params[key] = searchFormValue[key]);
        });
        let res = await settlementAdminAdminPage(params);
        if (res.code === 200) {
          const columns = [
            { title: "编号", dataIndex: "index" },
            { title: "会长名称", dataIndex: "presidentName" },
            { title: "账号名", dataIndex: "bankAccountName" },
            { title: "银行账号", dataIndex: "payeeAccount" },
            { title: "支行名称", dataIndex: "bankBranchName" },
            { title: "支付宝账号", dataIndex: "alipayAccount" },
            { title: "支付宝用户姓名", dataIndex: "alipayName" },
            { title: "提现金额", dataIndex: "outcomeAccount" },
            { title: "提现状态", dataIndex: "status" },
            { title: "申请时间", dataIndex: "outcomeDatetime" },
          ];
          const formData = (rawData) => {
            const data = rawData.map((item, index) => {
              const temp = {
                index: index + 1,
                presidentName: item.presidentName,
                bankAccountName: item.bankAccountName,
                payeeAccount: item.payeeAccount,
                bankBranchName: item.bankBranchName,
                alipayAccount: item.alipayAccount,
                alipayName: item.alipayName,
                outcomeAccount: item.outcomeAccount,
                status: item.status == 0 ? '申请中' : item.status == 2? '已驳回': item.status == 1 ? '已处理' : '取消申请',
                outcomeDatetime: item.outcomeDatetime,
              };
              return temp;
            });
            return data;
          };
          const data = formData(res.data.records);
          const excel = new Excel({
            columns,
            data: data,
            tablename: "佣金提现报表",
          });
          await excel.downFile('csv');
        }
      } catch (err) {
        console.error(err);
      }
    },
  },

}
</script>

<style scoped lang="less">
.content{
  padding: 20px;
  margin: 10px;
  border: 1px solid #dcdfe6;
  text-align: initial;
  background-color: white;
  .con-tab{
    background-color: #e6a23c2b;
    padding: 10px 8px;
    border-radius: 8px;
    font-size: 14px;
    color: #e6a23c;
    margin-bottom: 20px;
  }
  .page {
    text-align: right;
    margin-top: 15px;
  }
  .span-cursor{
    cursor: pointer;
    padding-right: 10px;
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar{
    width: 1px;
    height: 10px;
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: rgba(0,0,0,.2);
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-track{
    border-radius: 10px;
    background-color: rgba(0,0,0,.1);
  }
  ::v-deep .el-table__header th.el-table__cell{
    height:50px;
    background-color:#fafafa;
    color:#333333;
    padding:0
  }
}

</style>